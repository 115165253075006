

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    
    useDisclosure
  } from '@chakra-ui/react'

import JSONtoCSV from "../utils/JSONtoCSV";
export default  function FileExporter(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
      <>
        <Button marginLeft={"32px"} variant={"outline"} onClick={onOpen}>Export Data</Button>
  
        <Modal isOpen={isOpen} onClose={onClose} size={"sm"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Export Data</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text>
                Please first make sure right columns are chosen. Only currently filtered list will be exported.
                If you want to download the complete list, remove search and filters.
            </Text>
          
            </ModalBody>
  
            <ModalFooter textAlign={"left"}>
              <Button colorScheme='blue' mr={3} onClick={()=>{
                JSONtoCSV(props.data, props.columns, props.hiddenCols)
                onClose()
              }}>
                Export
              </Button>
              <Button colorScheme={"red"} onClick={()=>{
                onClose()
              }}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }