import {Text, Stack,Skeleton} from '@chakra-ui/react'

export default function LoadingSkeleton(){
    return(
        <div>
            <Text fontSize={'5xl'}>Loading instances.</Text>
            <Text fontSize={'4xl'}>Please wait.</Text>
            <Stack>
                <Skeleton height='20px' />
                <Skeleton height='20px' />
                <Skeleton height='20px' />
                <Skeleton height='20px' />
                <Skeleton height='20px' />
                <Skeleton height='20px' />
            </Stack>
        </div>
    )


}
