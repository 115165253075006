import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    NumberInput,
    NumberInputField,
    useDisclosure,
    Text,
    Input,
    Select,
    Divider
  } from '@chakra-ui/react'
import { useState,} from 'react';
import DateTimePicker from 'react-datetime-picker';

/*
The button which expands to provide date, time and justification for
starting/stopping/postponing the time for an instance

*/
export default function ModalButton(props) {
  console.log("modalbutton props")
  console.log(props)
    //const path = '/ec2instances'; 
    const [runDuration, setRunDuration] = useState()
    const [justification, setJustification] = useState("")
    const [note, setNote] = useState("")
    const { isOpen, onOpen, onClose } = useDisclosure()


    //set max duration to infinity if admin 72 hrs if not admin
    let maxDuration = props.userData.admintype==="1"?(Math.pow(10,1000)):72
    let maxDurationHint = props.userData.admintype!=="1"? " Max 72 hours.":""
    
    const handleInputChange = event => {
      if(props.adminType === "0" && parseFloat(event.target.value) > maxDuration ){
        alert("Warning: The duration will be automatically set to 72 Hours. Contact sandbox administrator if longer duration is required.")
        setRunDuration(72)
      }
      else{
        setRunDuration(event.target.value)
      }
        
    }
    

    /*
    Handle data from the datetimepicker which is a child component
    to this component, send this callback function as a prop to the child
    */
    const handleChildData = (duration) =>{
      console.log("setting duration from child")
      console.log(duration)
      setRunDuration(duration)
    }
    
    function handleJustChange(e){
      setJustification(e.target.value)
    }

    function handleNoteChange(e){
      setNote(e.target.value)
    }

    return (
      <>
      {
        (props.action === "stop")&&
        <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{props.description}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

            <Text>Stopping instance {props.instanceId}</Text>
              
            </ModalBody>
            
            <ModalFooter>
             {/*Pass the value submitted to the parent (ec2Table) */}
              <Button 
                colorScheme='blue' mr={3} 
                onClick={ 
                    event => {  
                          props.handlePassedData(0, props.instanceId, props.action,props.userData, props.instance, "", "")
                          onClose()

                            }
                 /*POSTPONE*/
                }>
                {props.buttontitle}
              </Button>
              <Button variant='tomato' onClick={
                onClose
              }>Cancel</Button>
            </ModalFooter>
          </ModalContent>


        </Modal>
        
          
        }
      
        {<Button colorScheme={props.buttonColor} onClick={
            () => {
                onOpen()
            }
            }>{props.buttontitle}</Button>
          }
  
        {((props.action==="start") || (props.action==="postpone")) &&
        <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{props.description}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <FormControl>
                    <FormLabel>Duration in hours.{maxDurationHint}</FormLabel>
                    <NumberInput mb={"15px"} min={0} max={maxDuration}>
                        <NumberInputField placeholder='Duration' onChange={handleInputChange} />
                    </NumberInput>
                    
                 
                    
                    {
                      props.userData.admintype ==="1" &&
                      <>
                      <Divider orientation='horizontal' size={'20px'} />
                      <FormLabel>Set a stop date and time (MM/DD/YYYY).</FormLabel>
                      <MyDateTimePicker handleChildData={handleChildData} disableClock={true} /></>
                      
                    }
                    <Divider />
                    {/* <FormLabel>Please provide a short justification for the action. </FormLabel>
                    <Input isRequired={true} onChange={handleInputChangeJust} placeholder='Justification' /> */}
                    <Select mb={"15px"} placeholder='Select Justification' onChange={handleJustChange}>
                    {
                      props.env.justifications.map((just) => {
                        return <option value={just}>{just}</option>
                    })
                  }
                </Select>

                <Text>Note (optional, max 100 characters)</Text>
                <Input placeholder='Ticket number, etc.' onChange={handleNoteChange} />
                </FormControl>
              
            </ModalBody>
            
            <ModalFooter>
             {/*Pass the value submitted to the parent (ec2Table) */}
              <Button 
                colorScheme='blue' mr={3} 
                onClick={

                  
                    event => {
                            if(justification !== ""){
                              props.handlePassedData(runDuration, props.instanceId, props.action,props.userData, props.instance, justification, note)
                              onClose()
                            }else{
                              alert("Justification is required")
                            }

                            }
                 /*POSTPONE*/
                }>
                {props.buttontitle}
              </Button>
              <Button variant='tomato' onClick={
                onClose
              }>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>}
      </>
    )
  }


  /*
    A react datetime picker widdget
    props: handleChildData(duration):
          a callback function from parent to send back data (duration)
    time is converted to Unix Timestamp
  */
  function MyDateTimePicker(props) {
    const [value, setValue] = useState(new Date());
   
    const onChange = (datevalue) =>{
      let unixTimeStamp = (datevalue.getTime()/1000).toFixed(0)
      let timeNow = (new Date().getTime()/1000).toFixed(0)

      let dur = unixTimeStamp - timeNow
      setValue(datevalue)
      if(dur <0){
        alert("You can't select time in the past. Plase try again.")
      }else{
        props.handleChildData((dur / 3600).toFixed(3))
      }
      
    }
    return (
      <div>
        <DateTimePicker 
        onChange={onChange} 
        value={value} 
        disableClock={true} 
        disableCalendar={true}
        closeWidgets={false}
        calendarIcon={""}
        clearIcon={""}
         />
      </div>
    );
  }