import React from "react";
import {
  Text,
  Input,
  Box,
  Grid,
  HStack,
  Center,
  Divider
} from "@chakra-ui/react";


import { useState, useEffect} from 'react';
import ModalButton from './modalbutton'
import LoadingSkeleton from './loadingSkeleton'
import {CustomTable} from "./customTable";
import { Stat } from "./stats";
import { UserCard } from "./userCardEc2";
import { tableColsMT } from "../data/tableColsMT";
import getData from "../utils/fetchData";
import PostData from "../utils/postData";
import DeleteData from "../utils/deleteData";
import { prepareDataForMTTable } from "../utils/prepareDataForMTTable";



// redefine console.log in order to disable console outputs
//comment out to show console outputs again.

console.log = function(){}

function ReactTableMulti(props) {
    console.log("react table envs")
    console.log(props.env)
    const API_ENDPOINT= props.env.api_url
    /*
    Main EC2Table component: heart of all functionality
    */
    
    const[instances, setInstances] = useState()
    const[filteredInstances, setFilteredInstances] = useState([])
    const[adminType, setAdminType]=useState()
    const[currUser, setCurrUser] = useState({"email":"", "admintype": "-1"})
    const path = '/tenants';
    


    /*
    Child componenent ModalButton uses this function as a callback to send the data to this compoennt

    params:
            duration: duration of turning on the instance
            id: instance Id
            action: start/stop
            email: email address of logged in user to initialise API request
    This data comes from the ModalButton once user puts in the value
    
    */

    const handlePassedData = (duration, tenantName, action, userData, instance, justification, note) =>{
        let p = ""
        let urlSafeJustStr = encodeURIComponent(justification)
        if(action === "postpone"){
            p = "/tenant/"+ tenantName + "?user=" + userData['email'] +"&action=start" + "&duration=" + duration +  "&justification="+urlSafeJustStr + "&env="+ instance.environment + "&region=" + instance.region
        }else{
            p = "/tenant/"+tenantName+"?user=" + userData['email'] +"&action=" + action  + "&duration=" + duration + "&justification="+urlSafeJustStr + "&env="+ instance.environment + "&region=" + instance.region
        }
        console.log(userData)
        let data = {
            tenantName: tenantName,
            region : instance.region,
            az: instance.az,
            env: instance.environment,
            duration: duration,
            user: userData["email"],
            justification: justification,
            action: action,
            note:note

        }
        if(action === "start" || action === "postpone"){
            
            PostData(API_ENDPOINT+p, props.user["id_tok"], data )
            .then(response => {
                // console.log("-----response----")
                // let status = response.Status
                // console.log(status)
                // if(status === "OK"){
                //     let newarr = array.map(item => item.hostname === tenantName ? {...item, action: "adsfk"} : item)
                //     setInstances(newarr)
                // }
                //response.data.StoppingInstances?setInstances(replaceInstanceDescription(instances, response.data.StoppingInstances[0]))
                //:setInstances(replaceInstanceDescription(filteredInstances, response.data.StartingInstances[0]))
                console.log(response)
                if(action === "postpone"){
                    alert("Info: The shutdown of the requested instance will be postponed.Please refresh the page to display the change.")   
                }else if (action === "start"){
                    alert("Info: The requested instance will be started shortly. Please refresh the page to display the change.")
                }
                else{
                    alert("Info: Shutdown scheduled. The instance will be shut down in the given time.")
                }
            })
            .catch(error => {
                console.log(error);
                alert(`Error: An error occured while trying to ${action} the instance . Please try again later or contact the Administrator. + error: ${error}`)
            });
        }
        if(action === "stop"){
            
            DeleteData(API_ENDPOINT+p, props.user["id_tok"])
            .then(response => {
                console.log(response)
                // let status = response.Status
                // console.log(status)
                // if(status === "OK"){
                //     let newarr = array.map(item => item.hostname === tenantName ? {...item, action: "adsfk"} : item)
                //     setInstances(newarr)
                // }
                //response.data.StoppingInstances?setInstances(replaceInstanceDescription(instances, response.data.StoppingInstances[0]))
                //:setInstances(replaceInstanceDescription(filteredInstances, response.data.StartingInstances[0]))
                alert("Info: The requested instance will be stopped shortly.Please refresh the page to display the change.")
            })
            .catch(error => {
                console.log(error);
                alert(`Error: An error occured while trying to ${action} the instance . Please try again later or contact the Administrator. + error: ${error}`)
            });
        }

    }

    /*
    Convert Unix Epoch time to date time format
    startup and shutdown time are stored as tags of ec2 instacnes in Unix epoch time

    params:
            timeEpoch: Unix Epoch Time
            offset: needed if it needs to be in another time zone. not used here.
    
    */


    const __handleSearch = (event) => {
      // setSearch(event.target.value)
       console.log(event.target.value)
       var search_results = []
       search_results = instances.filter((ins) =>{
        /*
            since all names are of form prefix.acrolinx.cloud, the search results all instances
            if any letter form the domain name is the first letter of the search
            avoid this problem by first removing the domain part of the name.
            let ins_prefix = ins.tags.Name.split(".")[0]
        */
        let ins_prefix = ins.hostname
        return ins_prefix.includes(event.target.value.toLocaleLowerCase())
       }
     
       );
       console.log(search_results)
       setFilteredInstances(search_results)
    /*       
        if (event.target.value !== '') {
                 search_results = instances.filter((ins) =>
                ins.tags.Name.toLowerCase().includes(event.target.value.toLocaleLowerCase())
                );
                
                    setFilteredInstances(search_results)
               
        } */
    };



    /*
    description:
                update the table content (instances array) with the new state after action is taken
    @params:
            instArray: current instances array : list of objects
            instDetails: details about the instance in answer sent by the API server: object    
    
    */
    // function replaceInstanceDescription(instArray, instDetails){
    //     const objIndex = instArray.findIndex((obj => obj.id === instDetails.InstanceId))
    //     console.log(instArray)
    //     instArray[objIndex].state = instDetails.CurrentState.Name

    //     return instArray
    // }


    useEffect(() =>{
                console.log(props)
                var res = props.user
                console.log("current user = " )
                console.log(props.user)
                const url = API_ENDPOINT+path+"?user="+(res['email'])+"&env="+ props.env.env
                console.log("url = "+ url)
                console.log("user = " + props.user["id_tok"] )
                getData(url, props.user["id_tok"] )
                .then(response => {

                    let arr = response.data
                    console.log(response)
                    let adminInfo = response.userInfo.admintype
                    setCurrUser(response.userInfo)
                    setAdminType(adminInfo)
                    let preparedInstances = prepareDataForMTTable(arr)
                    console.log(JSON.stringify(preparedInstances))
                    setInstances(preparedInstances)
                    setFilteredInstances(preparedInstances)
                    })
                    .catch(error => {
                        console.log(error);
                    });
    //actionInstance refers to the instance on which an action is performed
    // this is set as dependency so the table updates after an action to reflect new state
    },[])



  const columns = React.useMemo(
    () => [
        /*
        For the table columns which only reppresent information and do not have special
        functinality, e.g., buttons turning on and off, or manipulating UTC time to display
        on local time zone, these can be populated directly on the ../data/tableCols.js file
        We need a Header name and an accessor. Accessor can either be a key name of an element of
        the instances array we get from the API or arbitray if Cell is defined.
        */
          ...tableColsMT,
          {
            Header: "Start/Stop",
            accessor: "actionk",
            Cell: ({row})=>{

                if(row.original.justification !== "Permanently Active")
                {
                return row.original.action!=="start"?(
                    <div>
                        <ModalButton
                            key={"modalButton"+1}
                            buttonColor="blue"
                            buttontitle= "Start"
                            handlePassedData={handlePassedData}
                            description="Start an EC2 instance"
                            instanceId={row.original.hostname}
                            action={"start"}
                            adminType={adminType}
                            userData={currUser}
                            // all the info about the instance
                            instance = {row.original}
                            env = {props.env}
                            
                        />
                    </div>
                    ):(
                        <ModalButton
                            key={"modalButton"+2}
                            buttonColor="red" 
                            buttontitle= "Stop"
                            handlePassedData={handlePassedData}
                            description="Stop an instance"
                            instanceId={row.original.hostname}
                            action={"stop"}
                            adminType={adminType}
                            userData={currUser}
                            env={props.env}
                            // all the info about the instance
                            instance = {row.original}
                        />

                    
                    )
                }
                else{
                    return <Text>  </Text>
                }
            },
            sortType: ((rowA, rowB) => {
        
                
                if (rowA.original.state > rowB.original.state) return 1; 
                if (rowB.original.state > rowA.original.state) return -1;
                return 0;
         })
                 
          },
          {
            Header: "Shutdown Time",
            accessor: "shutdownTime",
            },
             
          {
            Header: "Postpone",
            accessor: "postpone",
            Cell: ({row})=>{
                if(row.original.justification !== "Permanently Active"){
                return row.original.state !== "stopped"?
                (<ModalButton
                    key={"modalButtonPostpone"+1}
                    buttonColor="blue"
                    buttontitle= "Postpone Shutdown"
                    handlePassedData={handlePassedData}
                    description="Postpone Shutdown"
                    instanceId={row.original.hostname}
                    action={"postpone"}
                    adminType={adminType}
                    userData={currUser}
                    instance = {row.original}
                    env={props.env}
                    />):
                    <h2>N/A</h2>
                }
                else{
                    return <Text>  </Text>
                }

            },
           

           
          }
        ],
    [currUser]
  );

  

  return(
    <div>
{/*     {(adminType === "5")?(<div>
            <Text fontSize={36}>Forbidden {adminType}.</Text>
        </div>):
        (!instances)? <><LoadingSkeleton /></>: */}
    <div>
    <Grid className="searchAndUserCard" templateColumns='repeat(3, 1fr)' gap={6}>
{     <HStack>
        <Stat data={instances} />
    </HStack>}
   
  
    <Center>
   
          
    <Box >
        <Text mb='0px'>Search instances</Text>  
            <Input
                borderColor='black'
                focusBorderColor='crimson'
                placeholder='search'
                onChange={e => __handleSearch(e)} />
        </Box>    
    </Center>     
    <UserCard email={props.user.email} adminType={adminType} />       
    </Grid>
    <Divider marginBottom={5} />
    {
        currUser && 
        instances?<CustomTable columns={columns} data={filteredInstances} />:<LoadingSkeleton />
    }
   
    </div>

  </div>
  )


}

export default ReactTableMulti;

