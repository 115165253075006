import timeCalculator from "./timeCalculator"
import EpochTimeToLocaleString from "./UTCtoLocalTime"

/*
    Function takes in the array of instances returned by the backend and does
    datetime calculations and adds some rows in order to make it easier for the
    ReactTabelMT function to easily display the columns

*/
export function prepareDataForMTTable(dataArr){
    let arr = dataArr
    console.log("array")
    console.log(arr)
     arr.map(
        (d) => {
            if(d){
                if(d.action === "start"){
                    let now = new Date()
                    let timeInEpochSecs = Math.round(now.getTime()/1000)
                    d["hoursSinceStart"] = timeCalculator(parseInt(d.timestamp), timeInEpochSecs)
                    d["daysRequested"] = timeCalculator(parseInt(d.timestamp), parseInt(d.scheduled_stop_time))
                    d["hoursTillShutdown"] = timeCalculator(timeInEpochSecs, parseInt(d.scheduled_stop_time))
                    d["startTime"] = EpochTimeToLocaleString(d.timestamp)
                    d["shutdownTime"] = EpochTimeToLocaleString(d.scheduled_stop_time)
                }
            }
        }
    ) 
    return arr
}
