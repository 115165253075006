
/*
wrapper around the fetch API get method 
@params:
    url: complete API url with query params
    googleIdTok: id token fetched from Google SSO login.
*/
async function getData(url = "", googleIdTok) {
  console.log("google auth tok: "+googleIdTok)
    // send the fetch request with relevant params and headers
    const response = await fetch(url, {
      method: "GET", 
         headers: {
        "Content-Type": "application/json",
        "authorizationToken": googleIdTok
      },
    });
    // get and return the JSON data from the response
    const respBody = await response.json()
    return respBody; 
  }
  export default getData