/*
utility funciton to calculate the difference between two UTC
time epochs and provide a pretty printed string with appropriate
time frame, i.e, mins, hours, days, etc.

@params:
    time1, time2: unix epoch timestamps (in seconds) for two times
    these are expected to be a int
*/

export default function timeCalculator(time1, time2){
    let outputStr = ""

    let offset = (time2-time1)/3600
    if(offset <0){
        outputStr = outputStr+ "N/A"
    }
    else
    if(offset>0 && offset<1){
        let mins = round(offset * 60,0)
        outputStr = outputStr+ mins + (mins ===1?" Minute":  " Minutes")
    }else
    if(offset >=1 && offset<=72){
        let hrs = round(offset,1)
        outputStr = outputStr + hrs + (hrs===1? " Hour": " Hours")
    }else{
        let days =  Math.floor(offset/24)
        let hours = round(offset%24,1)
        
        outputStr = outputStr + days + (days === 1? " Day":" Days ") + hours + (hours===1?" Hour":" Hours")
    }
    return outputStr

}

function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}