import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ChakraProvider} from '@chakra-ui/react'
import { GoogleOAuthProvider } from '@react-oauth/google';
const GOOGLE_CLIENT_ID = "48169464024-22harlms7bfpb9joot3h4r30089cbd07.apps.googleusercontent.com"


ReactDOM.render(
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ChakraProvider>
  
  <App />

</ChakraProvider>

    </GoogleOAuthProvider>

  
    
  ,
  document.getElementById('root')
);

