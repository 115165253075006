const envs = [
    {
        "env": "prod",
        "endpoint": "/ec2",
        "api_url": "https://4iwxvi4en5.execute-api.eu-west-1.amazonaws.com/prod",
        "description": "Sandbox Dashboard - Production",
        "legacy": true,
        "justifications": [
            "Cloud Migration",
            "Fodina Request",
            "Onboarding",
            "T1-T2 Highly Active",
            "Targets Migration",
            "Testing-Configurations",
            "Standard Under 72 Hours"
            ]
    },
    {   "legacy": true,
        "env": "qa",
        "endpoint": "/qa",
        "api_url": "https://sklltoerv5.execute-api.eu-west-1.amazonaws.com/prod",
        "description": "EC2 Dashboard - Staging",
        "justifications": [
            "Standard - under 72 Hours",
            "Long Term"
        ]
    },
    {
    "legacy": false,
    "env": "dev",
    "endpoint": "/mt-dev",
    "api_url": "https://api.sbod.sre-mgmt.acrolinx-cloud.net",
    "description": "DEV Multitenant",
    "justifications": [
        "Standard - under 72 Hours",
        "Long Term",
        "Permanently Active"

    ]
},
{
    "legacy": false,
    "env": "stg",
    "endpoint": "/mt-stg",
    "api_url": "https://api.sbod.sre-mgmt.acrolinx-cloud.net",
    "description": "STG Multitenant",
    "justifications": [
        "Standard - under 72 Hours",
        "Long Term"
    ]
},
{
    "legacy": false,
    "env": "sandboxes",
    "endpoint": "/mt-prod",
    "api_url": "https://api.sbod.sre-mgmt.acrolinx-cloud.net",
    "description": "PROD Multitenant",
    "justifications": [
        "Standard - under 72 Hours",
        "Long Term"
    ]
}
]



export {envs}