
import './App.css';

import {HStack, Button, Text, Stack} from '@chakra-ui/react'
import WithSubnavigation from './componenents/navigation';
import { useState, useEffect} from 'react';
import DashboardCard from './componenents/dashbaord_card'
import ec2 from '../src/images/ec2.svg'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import {jwtDecode} from "jwt-decode";
import { envs } from './data/environments';
import ReactTableMulti from './componenents/ReactTableMulti';
import getData from './utils/fetchData';

// auth endpoint of backend API, relevant only for MT world
const AUTH_API_ENDPOINT =  "https://api.sbod.sre-mgmt.acrolinx-cloud.net/auth" //
function App() {
  const [user, setUser] = useState(localStorage.email?{"email": localStorage.email, "id_tok": localStorage.id_tok}:null)
  const envArray = {
    "email": "",
    "adminTypes": {}
  }
  envs.forEach(obj=>{
    envArray.adminTypes[obj.env] = -1
  })
  const [auths, setAuths] = useState(localStorage.getItem("auths")?JSON.parse(localStorage.getItem("auths")):envArray)

  /*
    get what dashboards the user is supposed to see and interact with
    based on auth levels  
  */
  useEffect(() =>{
    console.log("auths emial " + auths.email)
    console.log(auths.email)
    if(user){
    var res = user
    const url = AUTH_API_ENDPOINT+"?user="+(res['email'])
    console.log("user = " + user["id_tok"] )
    getData(url, user["id_tok"] )
    .then(response => {
       let newAuths = {
          ...auths,
          "email": response.email,
          "adminTypes": response.adminTypes
        }
        console.log("new auth")
        console.log(newAuths)
        setAuths(newAuths)
        localStorage.setItem("auths", JSON.stringify(newAuths))
        })
        .catch(error => {
            console.log(error);
        });
    }

  },[user])


  return (
    <div className="App">
    <BrowserRouter>
  {
    /*
      Login out button depending upon if signed in or signed out
    */
  }
  <WithSubnavigation className="Navbar" user={user} signInOut={
      !user?<GoogleLogin
      auto_select={true} 
      onSuccess={credentialResponse => {
        
        var decoded = jwtDecode(credentialResponse.credential);
        setUser({"email": decoded.email, "id_tok": credentialResponse.credential})
        localStorage.setItem("email", decoded.email)
        localStorage.setItem("id_tok", credentialResponse.credential )
        
        console.log(decoded.email)
      }}
      onError={() => {
        console.log('Login Failed');
      }}
      useOneTap
    />:<Button
        background={"red"}
        color = {"whiteAlpha.900"}
        onClick={() => {
          googleLogout()
          localStorage.removeItem("email")
          localStorage.removeItem("id_tok")
          localStorage.removeItem("auths")
          setUser(null)
        }}
     >
      Sign Out
     </Button>
    }>
  </WithSubnavigation>




  {(user || localStorage.email)?
  ( <div
      className='contentDiv'>
      <Routes>
        <Route
          path="/" element={
            <div className='rootContainer'>
              { auths.adminTypes !== {}?
              <HStack 
                spacing='24px'>
                {
                  envs.map(el=>{
                    /*
                      for legacy (EC2 based) dashboards
                      Remove this if block if need to remove in future
                    */
                    // if(el.legacy){
                    //   return <DashboardCard 
                    //   path= {el.endpoint}
                    //   imageSource={ec2}
                    //   description = {el.description}
                    // />
                    // }
                    /*
                      For new dashboards, make sure to check auths too.
                    */
                    if(!el.legacy){
                      let el_auth_lev = auths.adminTypes[el.env]
                      return (el_auth_lev==="0" || el_auth_lev ==="1")?(
                      <DashboardCard 
                      path= {el.endpoint}
                      imageSource={ec2}
                      description = {el.description}
                    />):<Text>Loading Dashboard</Text>                     
                    }

                  })
                }

              </HStack>:<Text>Loading</Text>}
            
          
            </div>
      
          } />

        {
          envs.map(el=>{
            /*
              for legacy (EC2 based) dashboards
              Remove this if block if need to remove in future
            */
            // if(el.legacy){
            //   return(
            //   <Route
            //   path = {el.endpoint}
            //   element = {
            //     <div>
            //       {
            //         <ReactTable
            //           user={user}
            //           env={el}
            //           auths={auths}
            //         />
            //       }
            //     </div>
            //   }
            // ></Route>
            //   )
            // }

            /*
              For new dashboards, make sure to check auths too.
            */
            if(!el.legacy){
            let el_auth_lev = auths.adminTypes[el.env]
            console.log("el_auth_level = "+el_auth_lev)
            return (el_auth_lev==="0" || el_auth_lev ==="1")?(
              <Route
              path = {el.endpoint}
              element = {
                <div>
                  {
                    <ReactTableMulti
                      user={user}
                      env={el}
                      auths={auths}
                    />
                  }
                </div>
              }
            >
            </Route>
            ):null
          } 
          })
        }
      </Routes>
  </div>): 
  <div>
    <Stack
      spacing={3}>
      <Text 
        fontSize='6xl'>
          Welcome to Acrolinx SRE Dashboard
      </Text>
      <Text
        fontSize='5xl'>
        Please login to continue
      </Text>
    </Stack>
  </div>}
    
    </BrowserRouter>
    </div>

    
   
  );
}

export default App;
