import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Checkbox,
    VStack,
    Text,
    
    useDisclosure
  } from '@chakra-ui/react'
  import { useState } from 'react';
  import { defaultHiddenCols } from "../data/defaultHiddenCols";
export default  function ColumnChooser(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [hiddenCols, setHiddenCols] = useState(defaultHiddenCols)
    return (
      <>
        <Button marginLeft={"32px"} variant={"outline"} onClick={onOpen}>Choose Columns</Button>
  
        <Modal isOpen={isOpen} onClose={onClose} size={"sm"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Choose Columns</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text>
                Check the columns that should be shown. Uncheck to hide them.
            </Text>
            <br></br>
                <VStack align={"flex-start"} >
                  {
                    props.columns.map((colItem) => {
                      return(
                        <Checkbox onChange={
                          /*
                          Logic for the column selector
                          */
                          (e)=>{
                            if(!e.target.checked){
                              if(!hiddenCols.includes(colItem.accessor)){
                                //if the checkbox was previously unchecked and now checked
                                // add the column to hidden columns list
                                setHiddenCols(hiddenCols =>[...hiddenCols, colItem.accessor])
                                console.log(hiddenCols) 
                              }
                            }else{
                              // if the col was previously checked and now unchecked, remove it
                              // from the hidden column list
                              setHiddenCols(hiddenCols =>hiddenCols.filter((val) =>{return val===colItem.accessor?false:true}))
                            console.log(hiddenCols)     
                            
                            }

                           
                          }
                        } defaultChecked ={!hiddenCols.includes(colItem.accessor)}>{colItem.Header}</Checkbox>
                      )
                    })
                  }

                </VStack>
            </ModalBody>
  
            <ModalFooter textAlign={"left"}>
              <Button colorScheme='blue' mr={3} onClick={()=>{
                props.sendData(hiddenCols)
                onClose()
              }}>
                Done
              </Button>
              <Button colorScheme={"red"} onClick={()=>{
                    props.sendData(defaultHiddenCols)
                    onClose()
              }}>Reset</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }